import React, { useRef, useState } from "react";
import Product from "../Ui/Product";
import WEB_CONFIG from "../../config";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
// import {useNavigate} from 'react-router-dom';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductHeaderImage from "../Images/sections_image/Products.avif";
// eslint-disable-next-line react-hooks/rules-of-hooks

// /assets/img/sections_image/Products.avif

const Products = () => {
  const [categories, setCategories] = useState(WEB_CONFIG.CATEGORIES);
  const [inputFields, setInputFields] = useState({
    from_name: "",
    user_email: "",
    mobile: "",
    product_name: "",
  });

  const [isEmailSend, setEmailSend] = useState(false);

  const form = useRef();

  const validateValues = (inputValues) => {
    if (
      !inputValues.from_name ||
      !inputValues.user_email ||
      !inputValues.mobile ||
      !inputValues.product_name
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const isFormValid = validateValues(inputFields);
    if (!isFormValid) {
      return;
    } else {
      console.log();
      setEmailSend(true);
      emailjs
        .sendForm(
          WEB_CONFIG.emailConfig.serviceID,
          WEB_CONFIG.emailConfig.templateIDProduct,
          form.current,
          WEB_CONFIG.emailConfig.publicKey
        )
        .then(
          (result) => {
            if (result.text) {
              setEmailSend(false);
              toast("Your query has been raised!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            }
          },
          (error) => {
            setEmailSend(false);
            toast("Something went wrong! Please try after some time", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "error",
            });
          }
        );
    }
  };

  // const contactUsHandler =()=>{
  //   console.log("contact us called");
  // }

  return (
    <div className="product-component">
      <img
        className="img-fluid"
        src={ProductHeaderImage}
        alt="Products"
        style={{ width: "100%", height: "55vh", objectFit: "cover" }}
      />

      <section id="service" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <span>Our Products</span>
            <h2>Our Products</h2>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
            {/* <h3>About Us</h3> */}
            <p className="text-justify">
              From premium raw materials to finished products, our offerings are
              meticulously sourced and crafted to meet the highest standards of
              quality and reliability. Whether you're in search of cutting-edge
              technology, exquisite craftsmanship, or everyday essentials,
              discover our range of products and let your business thrive with
              Amrutsya.
            </p>
          </div>
          <section id="category" className="services pt-3">
            <div className="container" data-aos="fade-up">
              {categories.map((category) => (
                <div
                  className="category-section mb-4"
                  key={category.categoryName}
                >
                  <h3 className="category-title">{category.categoryName}</h3>
                  <div className="row">
                    {category.products.map((product) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 mb-4"
                        key={product.productTitle}
                      >
                        <div className="card h-100 shadow-sm category-product-card">
                          <img
                            src={product.imgUrl}
                            className="card-img-top"
                            alt={product.productTitle}
                            loading="lazy"
                          />
                          <div className="card-body">
                            <h5 className="card-title">
                              {product.productTitle}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
          <div className="row mt-4">
            {/* <h3>About Us</h3> */}
            <p className="fst-italic">
              COULD'NT FIND YOUR PRODUCT? DONT WORRY,
              <br />
              LET US KNOW WHAT YOU ARE LOOKING FOR, <br />
              AND WE WILL SHIP THAT TO YOU!
              <br />
              <br />
              JUST FILL THE FORM TO GET A CALLBACK FROM US
            </p>

            <form ref={form}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                    controlId="floatingInputName"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="from_name"
                      value={inputFields.from_name}
                      onChange={handleChange}
                      required
                    />
                  </FloatingLabel>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                    controlId="floatingInputEmail"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      name="user_email"
                      value={inputFields.email}
                      onChange={handleChange}
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                    controlId="floatingInputMobile"
                    label="Mobile number"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Mobile number"
                      value={inputFields.mobile}
                      onChange={handleChange}
                      name="mobile"
                      required
                    />
                  </FloatingLabel>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                    controlId="floatingInputProduct"
                    label="Product Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Product Name"
                      value={inputFields.product_name}
                      onChange={handleChange}
                      name="product_name"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 text-center">
                  {isEmailSend ? (
                    <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Sending...
                    </Button>
                  ) : (
                    <Button variant="primary" type="submit" onClick={sendEmail}>
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* { modalShow && 
                <ModalComponent
                show={modalShow}
                data={selectedProduct}
                onHide={() => setModalShow(false)}
              />
                
                } */}

      <ToastContainer />
    </div>
  );
};

export default Products;
