import './App.css';
import React from "react";
import Header from './components/sections/Header';
// import Home from './components/sections/Home';
import FooterTop from './components/sections/Footer-top';
import Footer from './components/sections/Footer';

import routes from './routes.js';
import {HashRouter  as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <React.Fragment>
      {/* <Home/> */}

      <Router>
      <Header/>
      
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} exact element={<route.component/>} />
        ))}
      </Routes>
      <FooterTop/>
      <Footer/>
      </Router>

      {/* <Footer/> */}
    </React.Fragment>
                );
}

export default App;
