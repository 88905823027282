const WEB_CONFIG = {
  NAVIGATION: [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "Our Team",
      route: "/our-team",
    },
    {
      name: "Products",
      route: "/products",
    },
    {
      name: "Suppliers",
      route: "/suppliers",
    },
    {
      name: "Certifications",
      route: "/certifications",
    },
    {
      name: "Contact Us",
      route: "/contact-us",
    },
  ],

  PRODUCTS: [
    {
      imgUrl: "/assets/img/products/Marble and Granite.jpg",
      productTitle: "Marble and Granite",
      description:
        "This is the description for Product 1. It has some key features and benefits that make it a great choice for customers.",
    },
    {
      imgUrl: "/assets/img/products/Tiles and SanitaryWear.jpg",
      productTitle: "Tiles and Sanitary wear",
      description:
        "Product 2 is a high-quality item with advanced technology. Its sleek design and powerful features set it apart from the competition.",
    },
    {
      imgUrl: "/assets/img/products/Spices.avif",
      productTitle: "Spices",
      description:
        "Introducing Product 3 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },

    {
      imgUrl: "/assets/img/products/bamboo.jpg",
      productTitle: "Bamboo products",
      description:
        "Introducing Product 5 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Grocery.jpg",
      productTitle: "Grocery Products",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Confectionary.avif",
      productTitle: "Confectionery",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Medicines.avif",
      productTitle: "Medicines and healthcare",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Engineering items.avif",
      productTitle: "Engineering items",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Malt Bevarages.avif",
      productTitle: "Malt based bevarages",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Incense Sticks.avif",
      productTitle: "Incense Sticks",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Handicrafts.jpg",
      productTitle: "Handicrafts",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Kitchenware.jpg",
      productTitle: "Kitchenware utensils",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Fruits and Vegetables.jpg",
      productTitle: "Fruits and vegetables",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Organic Products.jpg",
      productTitle: "Organic products",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Notebooks.avif",
      productTitle: "Note books",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Handicrafts.jpg",
      productTitle: "Handicarft items",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
    {
      imgUrl: "/assets/img/products/Bath Towels,Bedsheets.avif",
      productTitle: "Bath towels, bedsheets, blankets etc.",
      description:
        "Introducing Product 6 – the latest innovation in our product line. It's designed to meet the needs of modern consumers, offering durability and style.",
    },
  ],
  CATEGORIES: [
    {
      categoryName: "Everyday Essentials",
      products: [
        {
          imgUrl: "/assets/img/products/fruits-vegitables.jpg",
          productTitle: "Fresh Fruits and Vegetables",
          description:
            "A wide variety of fresh, organic fruits and vegetables sourced directly from local farms.",
        },
        {
          imgUrl: "/assets/img/products/Indian-herbs.jpg",
          productTitle: "Indian Herbs",
          description:
            "Premium quality Indian herbs perfect for culinary and medicinal uses.",
        },
        {
          imgUrl: "/assets/img/products/OrganicGrocery.jpg",
          productTitle: "Organic Grocery Products",
          description:
            "A comprehensive range of organic grocery items ensuring healthy and sustainable living.",
        },
        {
          imgUrl: "/assets/img/products/ColdPressedOils.jpg",
          productTitle: "Cold Pressed Oils",
          description:
            "Pure cold pressed oils extracted to retain natural flavors and nutrients.",
        },
        {
          imgUrl: "/assets/img/products/spices.jpg",
          productTitle: "Spices",
          description:
            "A diverse selection of spices to enhance the flavor of your dishes.",
        },
        {
          imgUrl: "/assets/img/products/GarlicPastePowder.jpg",
          productTitle: "Ginger and Onion (Flakes, Powder and Paste)",
          description:
            "Convenient garlic paste and fine garlic powder to add rich flavor to your meals.",
        },
        {
          imgUrl: "/assets/img/products/husk.png",
          productTitle: "Psyllium Husk",
          description:
            "Fresh ginger paste and aromatic ginger powder for your culinary needs.",
        },
        {
          imgUrl: "/assets/img/products/DriedOnionFlakes.jpg",
          productTitle: "Dried Onion Flakes",
          description:
            "High-quality dried onion flakes ideal for soups, stews, and various recipes.",
        },
        {
          imgUrl: "/assets/img/products/HennaPowder.jpg",
          productTitle: "Henna Powder",
          description:
            "Natural henna powder perfect for hair coloring and body art.",
        },
      ],
    },
    {
      categoryName: "Lifestyle Items",
      products: [
        {
          imgUrl: "/assets/img/products/HandBlockPrintedFabrics.png",
          productTitle: "Printed Fabrics",
          description:
            "Exquisite hand block printed fabrics ideal for traditional and contemporary fashion.",
        },
        {
          imgUrl: "/assets/img/products/WomenGarments.png",
          productTitle: "Women Garments - Indian and Western",
          description:
            "A stylish collection of women's garments blending traditional Indian and modern Western styles.",
        },
        {
          imgUrl: "/assets/img/products/PromotionalGiftingItems.jpg",
          productTitle: "Promotional and Gifting Items",
          description:
            "Customized promotional and gifting items perfect for corporate events and personal celebrations.",
        },
      ],
    },
  ],

  TEAM_MEMBERS: [
    {
      id: 1,
      name: "PRITHIPAL SINGH",
      role: "Chief Executive Officer (CEO)",
      description: "",
      avatar: "/assets/img/team/Prithipal singh.jpeg",
    },
    {
      id: 2,
      name: "SUNIL KUMAR",
      description: "",
      role: "Chief Financial Officer (CFO)",
      avatar: "/assets/img/team/Sunil Kumar.jpeg",
    },

    {
      id: 3,
      name: "Bhushaan Sharma",
      role: "Chief Operating Officer (COO)",
      description: "",
      avatar: "/assets/img/team/Bhushaan sharma.jpeg",
    },
    {
      id: 4,
      name: "Vanshika",
      role: "International Relations Manager",
      description: "",
      avatar: "/assets/img/team/Vanshika.jpeg",
    },
  ],
  emailConfig: {
    serviceID: "service_33dabyu",
    templateIDProduct: "template_7bdyexn",
    templateIDSupplier: "template_rnlo9xk",
    publicKey: "dt2rwYkmmvkXalqJH",
  },
};

export default WEB_CONFIG;
