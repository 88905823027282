import Home from "./components/sections/Home"; //Home
import OurTeam from "./components/sections/Our-team";
import Products from "./components/sections/Products";
import ProductDetails from "./components/sections/Product-details";
import Suppliers from "./components/sections/Suppliers";
import Certifications from "./components/sections/Certifications";
import ContactUs from "./components/sections/Contact-us";




const routes = [
  { path: '/', component: Home },
  { path: '/our-team', component: OurTeam },
  { path: '/products', component: Products},
  { path:'/product-details', component: ProductDetails},
  { path: '/suppliers', component:Suppliers},
  { path: '/certifications', component: Certifications},
  { path: '/contact-us', component: ContactUs}
];

export default routes;